#hmcContainer.carouselContainer {
	background-image: none;
	margin-bottom: 0;
}

#hmcContainer .carouselContent {
	width: auto;
	max-width: 1200px;
}

// CLS Desktop (AOL/ADE)
#aol .carouselContainer,
#ade .carouselContainer,
#ade .carouselContainer,
#ade .carouselContainer {
	.media-image {
		width: 2000px;
		height: 600px;
	}

	.bg-image-mobile {
		width: 750px;
		height: 975px;
	}
}

.carouselContainer {
	min-height: 560px;
	text-align: center;
}

#aol .carouselContainer.full-size,
#aol .carouselContainer.narrow,
#ade .carouselContainer.full-size,
#ade .carouselContainer.narrow {
	min-height: 565px;
}

#aol .carouselContainer.full-size .slide-content-lockup-inner,
#aol .carouselContainer.narrow .slide-content-lockup-inner {
	min-height: 300px;
}

// CLS Mobile (AOL/ADE)
#aol .carouselContainer.mobile,
#ade .carouselContainer.mobile {
	height: 973px !important;
	padding-bottom: 0 !important;
}

#aol .carouselContainer.mobile .slide-content-lockup-inner {
	min-height: 295px;
}

//Preload Image
// .carouselContainer.full-size ~ .carouselPreloadImg img,
// .carouselContainer.narrow ~ .carouselPreloadImg img,
// .carouselContainer.mobile ~ .carouselPreloadImg img {
//   display: none;
// }
.carouselPreloadImg {
	height: 356px !important;
	width: 100px !important;

	.img {
		height: 560px;
	}
}

.carouselContainer.full-size ~ .carouselPreloadImg img {
	height: 560px;
	width: 4000px;
}

.carouselContainer.narrow ~ .carouselPreloadImg img {
	height: 585px;
	width: 4000px;
}

.carouselContainer.mobile ~ .carouselPreloadImg img {
	height: 970px;
	width: 2000px;
}

//Hide HMC on desktop for test
.carouselContainer {
	.carouselContent {
		.hmcWidget {
			[data-tag-section='hmc-homepage'] {
				@media (min-width: 768px) {
					display: none;
				}
			}
		}
	}
}

#aol {
	.carouselContainer {
		min-height: 356px !important;
		background-color: white !important;

		@media (min-width: 756px) {
			min-height: 356px !important;
		}

		&.countdown-active {
			height: 382px !important;

			.carouselContent {
				height: 382px !important;
			}
		}

		.media-image {
			width: 1440px !important;
			height: 430px !important;
			left: 60% !important;

			@media (min-width: 756px) and (max-width: 990px) {
				left: 80% !important;
			}
		}

		.carouselContent {
			padding: 0 !important;
			margin: 16px;

			@media (min-width: 756px) {
				min-height: 356px;
			}

			@media (min-width: 990px) and (max-width: 1440px) {
				max-width: none;
				margin-left: 16px !important;
				margin-right: 16px !important;
			}

			@media (min-width: 1220px) {
				margin: 16px auto;
			}

			@media (min-width: 1440px) {
				max-width: 1440px;
			}

			.hmcWidget {
				min-height: 356px !important;
			}
		}

		.hero-stage {
			position: static !important;
			border-radius: 0.5rem !important;

			@media (min-width: 756px) {
				position: absolute !important;
				width: 100%;
			}

			.hero-slideshow {
				.boundary-container {
					.slide-boundary {
						width: 100% !important;
					}

					.slide-content-lockup {
						margin-top: 24px;

						@media (min-width: 756px) {
							position: absolute;
							top: 0;
							left: 0;
							right: 0;
							bottom: 0;
							margin: 24px 16px;
						}

						@media (min-width: 1200px) {
							margin: 24px auto 0;
							transform: translate(-320px);
						}

						.ao-cta {
							width: 233px !important;
							justify-content: center !important;
						}

						.lockup-image {
							margin-bottom: 16px !important;
						}
					}
				}
			}

			.slide-background-mobile {
				bottom: -300px;
			}
		}

		&.mobile {
			height: 356px !important;
			padding-bottom: 0 !important;

			.slide-content-lockup-inner {
				padding-left: 0 !important;
				padding-right: 0 !important;
			}

			&.countdown-active {
				height: 382px !important;

				.carouselContent {
					height: 382px !important;
				}
			}
		}

		&.narrow,
		&.full-size {
			.slide-content {
				padding: 0 !important;
			}

			.nav-container {
				.nav-controls {
					padding-left: 0 !important;
					width: 100%;
					max-width: 100% !important;

					.nav-controls-buttons {
						width: 100% !important;

						@media (min-width: 756px) {
							width: 335px !important;
							margin-left: 16px;
						}

						@media (min-width: 1200px) {
							width: 100% !important;
							transform: translate(-320px);
							margin-left: 0;
						}
					}
				}
			}
		}

		.nav-container {
			bottom: 4px !important;

			&.nav-ready {
				width: 100% !important;
			}

			.nav-controls {
				.nav-controls-buttons {
					.nav-prev,
					.nav-next {
						display: none;
					}
				}
			}
		}
	}
}

.carouselContent {
	position: relative;
	box-sizing: border-box;
	max-width: 1440px;
	height: 356px;
	overflow: visible;
	margin: 0 auto;
	padding-bottom: 30px;
	padding-left: 10px;
	padding-right: 10px;
	text-size-adjust: none;

	@media (min-width: 768px) and (max-width: 1440px) {
		margin-left: 16px;
		margin-right: 16px;
	}

	.hmcPromoContainer {
		position: absolute;
		width: 575px;
		top: 0;
		right: 0;
		margin: 0 auto;
		height: 620px;
		z-index: 2;
	}

	&.nowidget {
		width: 497px;

		.hmcWidget {
			display: none;
		}

		.hmcPromoContainer {
			width: 497px;
			right: initial;
		}
	}
}

//LIAMS CHANGES
.carouselContainer .slide-terms {
	font-family: Arial, Helvetica, sans-serif;
	line-height: 1.4;
	margin: 0;
	font-weight: 400;
	font-size: 12px;
	font-size: 0.75rem;
}

@media (min-width: 990px) {
	.carouselContainer .slide-terms {
		font-size: 13px;
		font-size: 0.8125rem;
	}
}

.ao-cta {
	display: inline-block;
	position: relative;
	min-width: 140px;
	max-width: 250px;
	padding: 11px 40px 11px 12px;
	border: 0;
	background-color: #7fba23;
	border-radius: 4px;
	overflow: hidden;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-appearance: none;
	font-size: 15px;
	font-size: 0.9375rem;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	text-align: left;
	color: #fff;
	line-height: 1.1;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.ao-cta:before {
	content: '';
	display: block;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border: 0 solid #fff;
	border-radius: 4px;
	-webkit-transition: background-color 0.1s ease-in, color 0.1s ease-in,
		-webkit-box-shadow 0.1s ease-in;
	transition: background-color 0.1s ease-in, color 0.1s ease-in,
		-webkit-box-shadow 0.1s ease-in;
	transition: box-shadow 0.1s ease-in, background-color 0.1s ease-in,
		color 0.1s ease-in;
	transition: box-shadow 0.1s ease-in, background-color 0.1s ease-in,
		color 0.1s ease-in, -webkit-box-shadow 0.1s ease-in;
}

.ao-cta:after {
	display: inline-block;
	content: '\e918';
	position: absolute;
	right: 12px;
	margin-left: 12px;
	font-family: ao-icons;
	vertical-align: text-bottom;
}

.ao-cta:hover {
	background-color: #9ad937;
	color: #fff;
	cursor: pointer;
}

.ao-cta:focus {
	-webkit-box-shadow: 0 0 0 3px #40a1f8;
	box-shadow: 0 0 0 3px #40a1f8;
	outline: none;
}

.ao-cta:focus:before {
	border-width: 1px;
}

.ao-cta.blue {
	background-color: #179dda;
}

.ao-cta.blue:hover {
	background-color: #3ab3ea;
}

.ao-cta.ghost-light {
	border: 1px solid currentColor;
	background: 0 0;
	color: #fff;
}

.ao-cta.ghost-light:hover {
	background-color: hsla(0, 0%, 100%, 0.1);
}

.ao-cta.ghost-dark {
	border: 1px solid currentColor;
	background: 0 0;
	color: #444;
}

.ao-cta.ghost-dark:hover {
	background-color: rgba(68, 68, 68, 0.1);
}

.ao-cta.link-dark,
.ao-cta.link-light {
	display: table;
	min-width: auto;
	padding-left: 0;
	background: 0 0;
	white-space: normal;
}

.ao-cta.link-dark:before,
.ao-cta.link-light:before {
	left: -12px;
	-webkit-transition: -webkit-box-shadow 0.1s ease-in;
	transition: -webkit-box-shadow 0.1s ease-in;
	transition: box-shadow 0.1s ease-in;
	transition: box-shadow 0.1s ease-in, -webkit-box-shadow 0.1s ease-in;
}

.ao-cta.link-dark:after,
.ao-cta.link-light:after {
	top: 50%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ao-cta.link-dark:focus,
.ao-cta.link-light:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	overflow: visible;
}

.ao-cta.link-dark:focus:before,
.ao-cta.link-light:focus:before {
	-webkit-box-shadow: 0 0 0 3px #40a1f8;
	box-shadow: 0 0 0 3px #40a1f8;
	border-width: 0;
}

.ao-cta.link-light {
	color: #fff;
}

.ao-cta.link-light:hover {
	color: #ccc;
}

.ao-cta.link-dark {
	color: #179dda;
}

.ao-cta.link-dark:hover {
	color: #68c5ef;
}

.ao-cta.disabled {
	padding: 11px 10px;
	text-align: center;
	cursor: default;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ao-cta.disabled,
.ao-cta.disabled:hover {
	background-color: #a3abb1;
}

.ao-cta.disabled:after {
	content: none;
}

.ao-cta.stacked {
	display: block;
}

.ao-cta.stacked + .stacked {
	margin-top: 16px;
}

.price-information {
	display: block;
	min-height: 32px;
	margin-top: 8px;
	line-height: 1;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: 700;
	color: #444;
	font-size: 32px;
	font-size: 2rem;
}

.price-information.has-saving {
	color: #bb0708;
}

.price-information span {
	vertical-align: top;
}

.price-information .savings {
	font-size: 11px;
	font-size: 0.6875rem;
	display: inline-block;
	margin-top: 2px;
	margin-left: 4px;
	font-weight: 400;
	line-height: 1.2;
	color: #333;
}

.price-information .savings .percentage {
	display: block;
	font-weight: 700;
	color: #bb0708;
}

.flex-inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	padding-right: 16px;
	padding-left: 16px;
}

@media (min-width: 768px) {
	.flex-inner {
		max-width: 768px;
	}
}

@media (min-width: 990px) {
	.flex-inner {
		max-width: 990px;
	}
}

@media (min-width: 1200px) {
	.flex-inner {
		max-width: 1200px;
	}
}

.flex-inner.col {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.flex-inner.col-reverse {
	-webkit-box-orient: vertical;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: column-reverse;
	-ms-flex-direction: column-reverse;
	flex-direction: column-reverse;
}

.flex-inner.row {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.flex-inner.row-reverse {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: reverse;
	-webkit-flex-direction: row-reverse;
	-ms-flex-direction: row-reverse;
	flex-direction: row-reverse;
}

.no-flexwrap .flex-inner {
	display: block;
}

.hmc-is-open .hero-stage {
	pointer-events: none;
	-webkit-filter: blur(3px) brightness(0.6);
	filter: blur(3px) brightness(0.6);
}

.newHome .carouselContainer.variantCards {
	background-color: #efeff4;
	background-image: none;
}

.carouselContainer picture {
	display: block;
}

.carouselContainer .progress-bar {
	position: absolute;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 5;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	width: 100%;
	height: 5px;
	background: hsla(0, 0%, 100%, 0.8);
	-webkit-transform-origin: left center;
	-ms-transform-origin: left center;
	transform-origin: left center;
}

.carouselContainer .progress-bar[data-theme='dark'] {
	background: rgba(0, 0, 0, 0.8);
}

.carouselContainer .skew {
	position: absolute;
	bottom: -80px;
	left: 0;
	z-index: 5;
	-webkit-transform: skewY(-1.3deg);
	-ms-transform: skewY(-1.3deg);
	transform: skewY(-1.3deg);
	width: 2000px;
	height: 200px;
	background-color: #f3f3f3;
}

.carouselContainer .hero-stage {
	-webkit-transition: -webkit-filter 0.2s ease-in-out;
	transition: -webkit-filter 0.2s ease-in-out;
	transition: filter 0.2s ease-in-out;
	transition: filter 0.2s ease-in-out, -webkit-filter 0.2s ease-in-out;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.carouselContainer .hero-stage,
.carouselContainer .slide {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.carouselContainer .slide {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
}

.carouselContainer .slide.transition-out {
	z-index: 2;
	-webkit-transition: opacity 0.3s ease-out;
	transition: opacity 0.3s ease-out;
	opacity: 0;
}

.carouselContainer .slide.transition-out .slide-content-lockup {
	-webkit-transform: translate3d(0, -15px, 0);
	transform: translate3d(0, -15px, 0);
}

.carouselContainer .slide.transition-out .slide-content-lockup,
.carouselContainer .slide.transition-out .slide-content-media {
	-webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease,
		-webkit-transform 0.2s ease;
	opacity: 0;
	-webkit-transition-delay: 0;
	transition-delay: 0;
}

.carouselContainer .slide.transition-out .slide-content-media {
	-webkit-transform: translate3d(0, -10px, 0);
	transform: translate3d(0, -10px, 0);
}

.carouselContainer .slide.transition-in {
	opacity: 1;
}

.carouselContainer .slide.next-slide {
	z-index: 1;
	opacity: 1;
}

.carouselContainer .slide.is-active {
	z-index: 2;
	opacity: 1;
}

.carouselContainer .slide.is-active .slide-content-lockup {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease,
		-webkit-transform 0.2s ease;
	opacity: 1;
	-webkit-transition-delay: 10ms;
	transition-delay: 10ms;
}

.carouselContainer .slide.is-active .slide-content-media {
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: opacity 0.2s ease, -webkit-transform 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease;
	transition: transform 0.2s ease, opacity 0.2s ease,
		-webkit-transform 0.2s ease;
	opacity: 1;
	-webkit-transition-delay: 25ms;
	transition-delay: 25ms;
}

.carouselContainer .ao-cta {
	min-width: 0;
	max-width: none;
	margin: auto;
}

.carouselContainer .slide-terms {
	padding-top: 16px;
}

.carouselContainer .slide-background {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100%;
}

.carouselContainer .slide-background img {
	max-width: none;
}

.carouselContainer .slide-background-desktop {
	display: none;
}

.carouselContainer .bg-image-desktop,
.carouselContainer .bg-image-mobile,
.carouselContainer .lockup-image-container,
.carouselContainer .media-image-container {
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	pointer-events: none;
}

.carouselContainer .slide-boundary {
	display: table;
	position: relative;
	z-index: 5;
	max-width: 1200px;
	height: 100%;
	margin: auto;
}

.carouselContainer .slide-content {
	display: table-cell;
	vertical-align: bottom;
	width: 100%;
	height: 100%;
	padding-bottom: 80px;
}

.carouselContainer .slide-content-lockup {
	position: relative;
	z-index: 2;
	-webkit-transform: translate3d(0, 15px, 0);
	transform: translate3d(0, 15px, 0);
	opacity: 0;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
}

.carouselContainer .lockup-image-container.lockup-image-container--full-size,
.carouselContainer .lockup-image-container.lockup-image-container--mobile,
.carouselContainer .lockup-image-container.lockup-image-container--narrow,
.carouselContainer .media-image-container.media-image-container--full-size,
.carouselContainer .media-image-container.media-image-container--mobile,
.carouselContainer .media-image-container.media-image-container--narrow {
	display: none;
}

.carouselContainer .slide-content-media {
	position: relative;
	z-index: 1;
	-webkit-transform: translate3d(0, 10px, 0);
	transform: translate3d(0, 10px, 0);
	opacity: 0;
	-webkit-transform-origin: center;
	-ms-transform-origin: center;
	transform-origin: center;
}

.carouselContainer .slide-content-media-inner {
	margin: auto;
	padding-top: 30px;
}

.carouselContainer .lockup-image {
	display: block;
	max-width: 100%;
	height: auto;
	margin: auto;
	margin-bottom: 30px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.carouselContainer .lockup-image.slide-lockup-full-size,
.carouselContainer .lockup-image.slide-lockup-mobile,
.carouselContainer .lockup-image.slide-lockup-narrow {
	display: none;
}

.carouselContainer .media-image {
	max-width: 100%;
	height: auto;
}

.carouselContainer .nav-container {
	position: absolute;
	bottom: 30px;
	z-index: 4;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	width: 100%;
	-webkit-transition: opacity 0.5s ease-in;
	transition: opacity 0.5s ease-in;
	opacity: 0;
	white-space: nowrap;
}

.carouselContainer .nav-container.nav-ready {
	opacity: 1;
}

.carouselContainer .nav-controls {
	display: table;
	max-width: 768px;
	margin: auto;
	padding: 0 16px;
}

.carouselContainer .nav-next,
.carouselContainer .nav-pagination,
.carouselContainer .nav-prev {
	display: inline-block;
	position: relative;
	vertical-align: middle;
	width: 40px;
	height: 40px;
	padding: 5px 10px;
	border: 0;
	line-height: 0;
	color: #fff;
	text-align: center;
	background: 0 0;
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	overflow: visible;
	cursor: pointer;
}

.carouselContainer .nav-next:focus,
.carouselContainer .nav-pagination:focus,
.carouselContainer .nav-prev:focus {
	border-radius: 4px;
	-webkit-box-shadow: 0 0 0 3px #40a1f8;
	box-shadow: 0 0 0 3px #40a1f8;
}

.carouselContainer .nav-next[data-theme='dark'],
.carouselContainer .nav-pagination[data-theme='dark'],
.carouselContainer .nav-prev[data-theme='dark'] {
	color: #000;
}

.carouselContainer .nav-pagination {
	font-size: 16px;
	font-size: 1rem;
}

.carouselContainer .nav-pagination:before {
	content: '';
	display: block;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
	width: 13px;
	height: 13px;
	margin: auto;
	border-radius: 50%;
	background-color: hsla(0, 0%, 100%, 0.5);
	-webkit-box-shadow: 0 0 6px rgba(0, 0, 0, 0.95);
	box-shadow: 0 0 6px rgba(0, 0, 0, 0.95);
	-webkit-transition: background-color 0.3s;
	transition: background-color 0.3s;
}

.carouselContainer .nav-pagination[data-theme='dark']:before {
	background-color: rgba(0, 0, 0, 0.5);
	-webkit-box-shadow: 0 0 6px hsla(0, 0%, 100%, 0.95);
	box-shadow: 0 0 6px hsla(0, 0%, 100%, 0.95);
}

.carouselContainer .nav-pagination.is-active:before {
	background-color: #fff;
}

.carouselContainer .nav-pagination[data-theme='dark'].is-active:before {
	background-color: #000;
}

.carouselContainer .nav-next,
.carouselContainer .nav-prev {
	position: relative;
	font-family: ao-icons;
	font-size: 30px;
	font-size: 1.875rem;
}

.carouselContainer .nav-next:before,
.carouselContainer .nav-prev:before {
	position: relative;
	z-index: 2;
	text-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.carouselContainer .nav-next[data-theme='dark']:before,
.carouselContainer .nav-prev[data-theme='dark']:before {
	text-shadow: 0 0 5px hsla(0, 0%, 100%, 0.6);
}

.carouselContainer .nav-prev:before {
	content: '\2c2';
}

.carouselContainer .nav-next:before {
	content: '\2c3';
}

.carouselContainer.mobile {
	height: 973px;
	padding-bottom: 0;
}

.carouselContainer.mobile .slide-background-mobile img {
	display: block;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 775px;
	height: auto;
}

.carouselContainer.mobile .skew {
	bottom: -15px;
	height: 200px;
}

.carouselContainer.mobile .hero-stage {
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: inherit;
	overflow: hidden;
}

.carouselContainer.mobile .slide-lockup-mobile {
	display: block;
}

.carouselContainer.mobile .slide-content-inner {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-flow: column;
	-ms-flex-flow: column;
	flex-flow: column;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	height: 550px;
}

.no-flexwrap .carouselContainer.mobile .slide-content-inner {
	display: block;
}

.carouselContainer.mobile .slide-content-lockup,
.carouselContainer.mobile .slide-content-media {
	width: 100%;
}

.carouselContainer.mobile .slide-content-lockup {
	display: table;
	width: 100%;
	height: 295px;
}

.no-flexwrap .carouselContainer.mobile .slide-content-lockup {
	height: 320px;
}

.carouselContainer.mobile .slide-content-lockup-inner {
	display: table-cell;
	vertical-align: top;
	width: 100%;
	height: 100%;
	padding: 0 16px;
}

.no-flexwrap .carouselContainer.mobile .slide-content-lockup-inner {
	vertical-align: middle;
}

.carouselContainer.mobile .lockup-image-container--full-size,
.carouselContainer.mobile .lockup-image-container--narrow {
	display: none;
}

.carouselContainer.mobile .lockup-image-container--mobile {
	display: block;
}

.no-flexwrap .carouselContainer.mobile .slide-content-media {
	display: table;
	width: 100%;
	height: 230px;
}

.no-flexwrap .carouselContainer.mobile .slide-content-media-inner {
	display: table-cell;
	vertical-align: bottom;
	width: 100%;
	height: inherit;
	padding-top: 0;
}

.carouselContainer.mobile .media-image-container--full-size,
.carouselContainer.mobile .media-image-container--narrow {
	display: none;
}

.carouselContainer.mobile .media-image-container--mobile {
	display: block;
}

.carouselContainer.mobile .media-image {
	display: block;
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 220px;
	margin: auto;
}

.carouselContainer.full-size .slide.is-fixed .slide-background-desktop img,
.carouselContainer.narrow .slide.is-fixed .slide-background-desktop img {
	right: auto;
}

.carouselContainer.full-size .skew,
.carouselContainer.narrow .skew {
	bottom: -80px;
	height: 200px;
}

.carouselContainer.full-size .hero-stage,
.carouselContainer.narrow .hero-stage {
	display: block;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 100%;
	height: inherit;
	overflow: hidden;
}

.carouselContainer.full-size .slide-background-mobile,
.carouselContainer.narrow .slide-background-mobile {
	display: none;
}

.carouselContainer.full-size .slide-background-desktop,
.carouselContainer.narrow .slide-background-desktop {
	display: block;
}

.carouselContainer.full-size .slide-background-desktop img,
.carouselContainer.narrow .slide-background-desktop img {
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	height: 560px;
}

.carouselContainer.full-size .slide-content,
.carouselContainer.narrow .slide-content {
	padding-top: 0;
	padding-bottom: 0;
}

.carouselContainer.full-size .slide-content-inner,
.carouselContainer.narrow .slide-content-inner {
	display: table;
	width: 100%;
	height: 100%;
}

.carouselContainer.full-size .slide-content-lockup,
.carouselContainer.full-size .slide-content-media,
.carouselContainer.narrow .slide-content-lockup,
.carouselContainer.narrow .slide-content-media {
	display: table-cell;
	vertical-align: middle;
	height: 100%;
}

.carouselContainer.full-size .slide-content-lockup,
.carouselContainer.narrow .slide-content-lockup {
	width: 335px;
}

.carouselContainer.full-size .slide-content-media-inner,
.carouselContainer.narrow .slide-content-media-inner {
	position: relative;
	width: 100%;
	max-width: none;
	height: 100%;
	padding-top: 0;
}

.carouselContainer.full-size .media-image,
.carouselContainer.narrow .media-image {
	display: inline-block;
	position: absolute;
	bottom: 0;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	max-width: none;
}

.carouselContainer.full-size .nav-controls,
.carouselContainer.narrow .nav-controls {
	display: block;
	padding-right: 0;
	padding-left: 275px;
}

.carouselContainer.full-size .nav-controls-buttons,
.carouselContainer.narrow .nav-controls-buttons {
	width: 335px;
}

.carouselContainer.full-size .nav-next,
.carouselContainer.full-size .nav-pagination,
.carouselContainer.full-size .nav-prev,
.carouselContainer.narrow .nav-next,
.carouselContainer.narrow .nav-pagination,
.carouselContainer.narrow .nav-prev {
	margin: 0 5px;
}

.carouselContainer.full-size .nav-next span,
.carouselContainer.full-size .nav-pagination span,
.carouselContainer.full-size .nav-prev span,
.carouselContainer.narrow .nav-next span,
.carouselContainer.narrow .nav-pagination span,
.carouselContainer.narrow .nav-prev span,
.carouselContainer.narrow .slide-lockup-narrow {
	display: block;
}

.carouselContainer.narrow .slide-content {
	padding-right: 16px;
	padding-left: 276px;
}

.carouselContainer.narrow .lockup-image-container--full-size,
.carouselContainer.narrow .lockup-image-container--mobile {
	display: none;
}

.carouselContainer.narrow .lockup-image-container--narrow {
	display: block;
}

.carouselContainer.narrow .media-image-container--full-size,
.carouselContainer.narrow .media-image-container--mobile {
	display: none;
}

.carouselContainer.narrow .media-image-container--narrow {
	display: block;
}

.carouselContainer.full-size .hero-stage {
	width: 2000px;
	/* height:inherit; */
}

@media (min-width: 2001px) {
	.carouselContainer.full-size .hero-stage {
		width: 2560px;
	}
}

.carouselContainer.full-size .slide-lockup-full-size {
	display: block;
}

.carouselContainer.full-size .slide-content {
	padding-right: 16px;
	padding-left: 341px;
}

.carouselContainer.full-size .lockup-image-container--mobile,
.carouselContainer.full-size .lockup-image-container--narrow {
	display: none;
}

.carouselContainer.full-size .lockup-image-container--full-size {
	display: block;
}

.carouselContainer.full-size .media-image-container--mobile,
.carouselContainer.full-size .media-image-container--narrow {
	display: none;
}

.carouselContainer.full-size .media-image-container--full-size {
	display: block;
}

.carouselContainer.full-size .nav-container {
	bottom: 25px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}

.carouselContainer.full-size .nav-controls {
	margin: 0;
	padding-left: 340px;
}

@media (min-width: 1200px) {
	.carouselContainer.full-size .nav-controls {
		margin: auto;
		padding-left: 250px;
	}
}

.vi-update .ao-cta.cta::after {
	position: static;
}

.vi-update .carouselContainer.mobile .slide-content-media {
	display: none;
}

.vi-update .carouselContainer.full-size .media-image,
.vi-update .carouselContainer.narrow .media-image {
	top: 0;
}

.vi-update .carouselContainer.full-size .slide-background-desktop img,
.vi-update .carouselContainer.narrow .slide-background-desktop img {
	height: 100%;
}

.vi-update .carouselContainer .media-image {
	min-height: 100%;
}

.carouselContainer .boundary-container {
	position: relative;
	width: 100%;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	height: 100%;
}

.carouselContainer .slide {
	overflow: hidden;
}
