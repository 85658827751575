#carousel img {
	visibility: hidden;
}

.promotions {
	line-height: 0;

	img {
		width: 100%;
	}

	.pod {
		min-height: 116px;
	}
}

img:not([src]):not([srcset]) {
	visibility: hidden;
}

.upperMerchPods {
	height: 100%;
	width: 100%;

	.pod-1-3 {
		flex: 1 1 auto;
	}

	.pod-2-3 {
		flex: 1 1 auto;
	}

	.pod-3-3 {
		flex: 1 1 auto;
	}

	@media (min-width: 768px) {
		max-width: 768px;

		.pod-2-3 {
			flex-grow: 2;
			flex-basis: 508px;
		}

		.pod-1-3 {
			max-height: 180px; // IE11
			flex-grow: 1;
			flex-basis: 248px;
		}
	}
	@media (min-width: 990px) {
		max-width: 990px;

		.pod-1-3 {
			flex-basis: 319px;
			max-height: 280px; // IE11
		}

		.pod-2-3 {
			flex-basis: 654px;
		}

		.pod-3-3 {
			flex-basis: 990px;
		}
	}
	@media (min-width: 1200px) {
		max-width: 1200px;

		.pod-1-3 {
			flex-basis: 388px;
			max-height: 340px; // IE11
		}

		.pod-2-3 {
			flex-basis: 794px;
		}

		.pod-3-3 {
			flex-basis: 1200px;
		}
	}
}

.lowerMerchPods {
	max-width: 672px; // These pods are carousel at this point

	.pod-1-3 {
		flex: 1 1 320px;
		margin: 0.5rem;
	}

	.pod-1-3-split {
		flex-grow: 2;
		margin: 0.5rem;
	}

	.pod-2-3 {
		flex: 2 1 508px;
		margin: 0.5rem;
	}

	.pod-3-3 {
		max-width: 990px;
	}

	.pod-single {
		flex-grow: 0; // when pods dont fill row prevent growth of last pod
	}

	@media (min-width: 768px) {
		.pod-1-3-split {
			max-height: 250px; // IE11
			width: 100%; // IE11

			.flex > div {
				max-height: 116px; // IE11
			}
		}
	}
	@media (min-width: 1200px) {
		max-width: 1008px;

		.pod-1-3-split {
			max-height: 116px; // IE11
			flex: 1 1 320px;
		}
	}
}

.merchPodCarouselWrap {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: auto;
	width: 260px;

	.control {
		cursor: pointer;
	}

	.merchPodCarousel {
		max-height: 194px;
		display: flex;
		overflow: hidden;

		.carouselItem {
			transition: all 0.5s ease;
			will-change: transform;
		}

		img {
			width: 246px;
		}

		.carouselItemSplit div a:first-child {
			display: block;
			margin-bottom: 10px;
		}
	}
}

.merchPrev {
	left: -36px;
}
.merchNext {
	right: -40px;
}

.moveSlide {
	margin-left: -262px;
}

// Animation
.lowerMerchPods .fade,
.upperMerchPods .fade {
	animation-name: fade;
	animation-duration: 1.4s;
}

@keyframes fade {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
