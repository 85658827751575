.tuk-top-section-wrap {
	background: url(//media.ao.com/tuk/img/hmc_def_bg.jpg) no-repeat 50%;
	background-size: cover;

	&:after {
		content: '';
		background-color: rgba(0, 0, 0, 0.4);
		opacity: 1;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}
}

.tuk-top-section-content {
	// Preset styles to help with CLS on mobile
	@media screen and (max-width: 755px) {
		.business-block {
			margin: 18px auto 0;
			max-width: 384px;
			width: 100%;
			padding: 25px 20px 18px;
		}
	}

	// Preset styles to help with CLS on desktop
	@media screen and (min-width: 756px) {
		min-height: 569px;
		max-width: 1200px;

		.business-block {
			position: absolute;
			right: 16px;
			left: 353px;
			top: 31px;
			height: calc(100% - 62px);
		}
	}

	.hmcContainer {
		width: auto !important;
		background-color: transparent !important;
		background-image: none !important;
	}

	.hmcContainer.mobile {
		.business-block {
			position: relative;
			top: 0;
			left: 0;
			right: 0;
			margin: 18px auto 0;
			max-width: 384px;
			width: 100%;
			padding: 25px 20px 18px;
		}
	}

	.hmcContainer.narrow {
		.business-block {
			position: absolute;
			top: 31px;
			left: 287px;
			right: 16px;
			padding: 25px 20px 18px;
			height: calc(100% - 62px);
			margin: 0;
		}
	}

	.hmcContainer.full-size {
		margin: auto;

		.business-block {
			position: absolute;
			top: 31px;
			left: 353px;
			right: 16px;
			padding: 25px 20px 18px;
			height: calc(100% - 62px);
		}
	}
}

@media screen and (min-width: 1200px) {
	.tuk-top-section-content {
		.business-block {
			padding: 34px 62px !important;
		}
	}
}

.business-block {
	.create-account {
		box-sizing: border-box;
	}

	.vert-separator:before {
		top: -3.5rem;
	}
	.vert-separator:after {
		bottom: -3.5rem;
	}

	.vert-separator:after,
	.vert-separator:before {
		content: '';
		position: absolute;
		height: 3rem;
		width: 1px;
		background: #d6dddf;
		left: 50%;
	}
}

.tuk-first-choice-section {
	.picture {
		background: url(//media.ao.com/uk/landingPages/b2b/img/delivery-van.jpg)
			no-repeat;
		background-size: cover;
		background-position: 50%;
		right: 6.9%;

		&:after {
			content: '';
			position: absolute;
			background: #fff;
			-webkit-transform: rotate(8deg);
			transform: rotate(8deg);
			-webkit-transform-origin: top;
			transform-origin: top;
			box-shadow: 0 0 1px #fff;
			display: block;
			top: 0;
			left: 100%;
			height: 140%;
			width: 40%;
		}
	}

	ul {
		a:after {
			content: '\2c3';
			font-family: ao-icons;
			margin-left: 5px;
			position: relative;
			top: 1px;
			opacity: 1;
			font-size: 11px;
			font-size: 0.6875rem;
		}
	}

	.first-choice-content {
		max-width: 600px;
	}
}
