.main-container {
  max-width: 768px;

  .marken {
    min-width: 0;
    flex: 0 1 30%;
    
    a {
      min-height: 40px;
      width: auto;
      background-position: center center;
      background-size: contain;

      @media (min-width: 768px) {
        background-position: left center;
      }
    }

    @media (min-width: 768px) {
      flex-basis: 96px;
    }
    @media (min-width: 990px) {
      flex-basis: 124px;
    }
    @media (min-width: 1200px) {
      flex-basis: 152px;
    }
  }

  .top {
    top: 8px;
  }
  .textIndent {
    text-indent: -9999px;
  }
  @media (min-width: 768px) {
    max-width: 768px;
  }
  @media (min-width: 990px) {
    max-width: 990px;
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
}


